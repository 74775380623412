import axios from "axios";
import helpers from "../shared/layouts/helpers";
class AuthService {
    login(username, password) {
        return new Promise(function(resolve, reject) {
            helpers.getAPI().then(api => {
                resolve(axios
                    .post(api + "/nsp/account/login", JSON.stringify({
                        username: username,
                        password: password,
                        country: localStorage.getItem('connectToRef')
                    })).then(response => {
                        if (response.data.accessToken) {
                            localStorage.setItem("mentechCustomerDetails", JSON.stringify(response.data));
                        }

                        return response.data;
                    })
                )
            })
        });
    }

    logout() {
        localStorage.removeItem("mentechCustomerDetails");
        window.location = "/";
    }

    getCurrentUser() {
        return JSON.parse(localStorage.getItem('mentechCustomerDetails'));
    }

    refreshToken() {
        let user = this.getCurrentUser()
        user.accessToken = null;
        localStorage.setItem("mentechCustomerDetails", JSON.stringify(user));
        const headers = {
            'X-Authorization': user.refreshToken
        }

        return axios
            .post(helpers.getApiByCountry(user.connectTo) + "/nsp/account/refresh/token", null, {headers: headers})
            .then(response => {
                let user = this.getCurrentUser();
                user.accessToken = response.data.accessToken;
                localStorage.setItem("mentechCustomerDetails", JSON.stringify(user));

                return response.data;
            });
    }
}

export default new AuthService();