import React, {Component, useContext} from 'react'
import helpers from "./shared/layouts/helpers";
import Router from './shared/Router'
import './vendor/styles/bootstrap.scss'
import './vendor/styles/appwork.scss'
import './vendor/styles/theme-corporate.scss'
import './vendor/styles/colors.scss'
import './vendor/styles/uikit.scss'
import './App.scss'
import './index.css'
import axios from "axios";
import Login from "./components/Dashboard";
import AuthService from "./services/auth-service";
import AnonymousRouter from "./shared/AnonymousRouter";
import ErrorBoundary from "./shared/ErrorBoundary";
import ReactGA from "react-ga4";
import BingTracking from "react-tracking-bing";
import ReactPixel from 'react-facebook-pixel';


helpers.getDefaultConnectTo(helpers).then((connectTo) => {
  let keys = {
    "DK": "G-E88JNDJE39",
    "NO": "G-C4017LWL48",
    "SE": "G-L86L2PHRV3",
    "DEV": null
  };

  if (keys[connectTo]) {
    ReactGA.initialize(keys[connectTo]);
  }
});
BingTracking.initialize("121000891")

const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};
ReactPixel.init("1417191881921905", advancedMatching, options)

class App extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <ErrorBoundary><Router {...this.props} /></ErrorBoundary>;
  }
}

axios.interceptors.request.use(function (config) {
  if (AuthService.getCurrentUser() && !AuthService.getCurrentUser().switching && AuthService.getCurrentUser().accessToken) {
    config.headers.common["X-Authorization"] = AuthService.getCurrentUser().accessToken;
  }
  return config;
});

axios.interceptors.response.use(response => {
  return response;
}, error => {
  if (AuthService.getCurrentUser() && !AuthService.getCurrentUser().switching && error.response.status === 403 ) {
    return AuthService.refreshToken().then(() => {
        error.config.headers["X-Authorization"] = AuthService.getCurrentUser().accessToken;
        return axios.request(error.config);
    }).catch(error => {
      console.log(error);
      AuthService.logout();
    });
  } else if (!AuthService.getCurrentUser() && error.response.status == 403) {
    if (!error.config.url.includes("login")) {
      AuthService.logout();
    }
  }

  if (AuthService.getCurrentUser() && error.response && error.response.status === 404) {
    //window.location = "/404";
  }

  return Promise.reject(error);
});

export default App
