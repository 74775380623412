import React, { Component } from 'react'
import { connect } from 'react-redux'
import helpers from "./helpers";
import axios from "axios";
import {withTranslation} from "react-i18next";

class LayoutFooter extends Component {
    constructor(props) {
        super(props);
        this.state = {key: 0};
    }

    componentDidMount() {
        console.log("mount");
        helpers.getAPI().then((api => {
            axios.get(api + (helpers.getUser() ? '/nsp/account/get/footer' : '/nsp/get/footer')).then(res => {
                this.setState({...res.data});
            })
        }))
    }

    render() {
        return (
          <nav key={this.state.key} className={`layout-footer footer bg-${this.props.footerBg}`}>
              <div className="container-fluid d-flex flex-wrap text-center container-p-x pb-3">
                  {this.state.name && <div className="pt-3 text-left" >
                      <small className={"text-muted"}>
                          <span className="footer-text font-weight-bold "><i className={"fas fa-copyright"}/> {new Date().getFullYear()} {this.state.name}. {this.props.t('All rights reserved')}</span>
                          <br/>
                          <span className="footer-text font-weight-bold text-left"><i className={"ion ion-ios-mail"}/> {this.state.email}</span>
                          <span className="footer-text font-weight-bold text-left ml-2"><i className={"ion ion-ios-call"}/> {this.state.phone}</span>
                          <span className="footer-text font-weight-bold text-left ml-2"><i className={"ion ion-ios-text"}/> {this.state.sms}</span>
                      </small>
                  </div>}
              </div>
          </nav>
        )
      }
    }

export default withTranslation()(connect(store => ({
  footerBg: store.theme.footerBg
}))(LayoutFooter))
